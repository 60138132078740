const isLocal = process.env.NODE_ENV !== 'production';

// TODO: make the port configurable. on the client-side,
// `process.env.NODE_ENV` is actually configured via webpack,
// so that would be the best place to grab the actual port[^1] and send it to the front-end.
//
// [^1]: see [APP_HTTP_PORT](https://github.com/twinlabs/streameater/blob/794a9be997165cf27a01a5b19999a983a34a232b/.env).

export const baseHost = isLocal ? `localhost:23000` : `music.links.club`;
export const baseUrl = isLocal ? `http://${baseHost}` : `https://${baseHost}`;
export const baseWebSocketUrl = isLocal
  ? `ws://${baseHost}`
  : `wss://${baseHost}`;

export default async function fetchJSONFromServer(
  endpoint: string,
  options?: object,
) {
  return (await fetchFromServer(endpoint, options)).json();
}

export async function fetchFromServer(endpoint: string, options?: object) {
  const response = await fetch(`${baseUrl}${endpoint}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...options,
  });

  return response;
}
