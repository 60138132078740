import * as Sentry from '@sentry/browser';
import logger from '~/client/utils/logger';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import type {
  NativeStackScreenProps,
  NativeStackNavigationProp,
} from '@react-navigation/native-stack';
import { RootStackParamList } from '~/client/NavigationConfig';

import ActivityIndicator from '~/client/components/ActivityIndicator';
import Text from '~/client/components/Text';
import { getUrlType } from '~/client/utils/getUrlType';
import { baseWebSocketUrl } from '~/client/utils/fetchJSONFromServer';
import navigateToResource from '~/client/utils/navigateToResource';

export default function WaitingQueue({
  route: {
    params: { resourceSourceUrl },
  },
}: NativeStackScreenProps<RootStackParamList, 'Waiting'>) {
  const navigation =
    useNavigation<
      NativeStackNavigationProp<
        RootStackParamList,
        'Song' | 'Album' | 'Playlist'
      >
    >();

  useEffect(() => {
    const socket = new WebSocket(baseWebSocketUrl);

    // Connection opened
    socket.addEventListener('open', function (event) {
      socket.send(JSON.stringify({ url: resourceSourceUrl }));
    });

    // Listen for messages
    socket.addEventListener('message', function (event) {
      let response;
      try {
        response = JSON.parse(event.data);

        const sourceUrlType = getUrlType(resourceSourceUrl).type;

        if (response.originalUrl === resourceSourceUrl) {
          return navigateToResource({
            navigation,
            resourceType: sourceUrlType,
            resourceId: response.resourceId,
          });
        }
      } catch (e) {
        logger.error('could not parse event', event);
        Sentry.captureException(
          new Error(`Could not parse Event. ${event.data}`),
        );
      }
    });

    setTimeout(() => {
      Sentry.captureMessage(
        `Url took unexpectedly long to process and user was redirected to dashboard. ${resourceSourceUrl}`,
      );

      navigation.navigate('Home');
    }, 30 * 1000);
  }, []);
  return (
    <View>
      <Text style={{ fontSize: 24 }}>Processing URL...</Text>
      <View style={{ marginBottom: 8 * 3 }} />
      <ActivityIndicator />
    </View>
  );
}
