import * as Sentry from '@sentry/browser';
import React, { useState, useEffect } from 'react';
import { View, Text, Button } from 'react-native';

import {
  APP_NAME,
  PASSWORD_VALIDATION_ERROR_MESSAGES,
} from '~/client/utils/constants';
import TextInput from '~/client/components/TextInput';
import ErrorComponent from '~/client/components/Error';
import { fetchFromServer } from '~/client/utils/fetchJSONFromServer';

export default function Signup() {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  const [tokenValidity, setTokenValidity] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordMatches, setPasswordMatches] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async () => {
    const response = await fetchFromServer(`/auth/password/create`, {
      method: 'POST',
      body: JSON.stringify({
        password: password,
        passwordConfirmation: passwordConfirmation,
        token: token,
      }),
    });

    if (response.status === 201) {
      window.location.href = '/';
    }

    const body = await response.json();
    const errorMessage = PASSWORD_VALIDATION_ERROR_MESSAGES.get(body.result);

    if (errorMessage) {
      setError(errorMessage);
    } else {
      setError('An unknown error occurred.');
      Sentry.captureException(
        new Error(
          `User got unknown error from back-end. ${
            body.result ?? 'Unknown error.'
          }`,
        ),
      );
    }
  };

  useEffect(() => {
    [password, passwordConfirmation].forEach((p) => {
      if (password === passwordConfirmation) {
        setPasswordMatches(true);
      } else {
        setPasswordMatches(false);
      }
    });
  }, [password, passwordConfirmation]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchFromServer(`/auth/signup?token=${token}`);

      if (response.status === 200) {
        setTokenValidity(true);
      }
    };

    fetchData();
  }, [tokenValidity]);

  return (
    <View className="mt-16 p-4 mb-4 bg-white drop-shadow rounded-sm dark:bg-gray-800">
      {tokenValidity ? (
        <View>
          <View className="mb-4">
            <Text className="dark:text-white">Welcome back to {APP_NAME}</Text>
          </View>
          <View className="mb-4">
            <Text className="dark:text-white">
              Please choose a password. Password must contain a combination of
              upper and lower-case letters and numbers.
            </Text>
          </View>
          <View>
            <ErrorComponent errorText={error} />
            <TextInput
              id="password"
              name="password"
              placeholder={'Enter Your Password'}
              onChange={({ nativeEvent: { text } }) => setPassword(text)}
              type="password"
            />
            <TextInput
              id="passwordConfirm"
              name="passwordConfirm"
              placeholder={'Confirm Your Password'}
              onChange={({ nativeEvent: { text } }) =>
                setPasswordConfirmation(text)
              }
              type="password"
            />
            <Button
              title="Submit Password"
              disabled={!password.length || !passwordMatches}
              onPress={handleSubmit}
            />
          </View>
        </View>
      ) : (
        <View style={{}}>
          {' '}
          {/* TODO: link to the Sign Up modal from here. */}
          <Text className="dark:text-white">
            Your signup token is either invalid or expired. Please Sign Up with
            your email again :)
          </Text>
        </View>
      )}
    </View>
  );
}
