import React from 'react';
import { View, ViewStyle } from 'react-native';

export default function Box({
  children,
  marginVertical = '2rem',
  padding = '0.5rem',
  borderWidth = 1,
  borderStyle = 'solid',
  borderColor = 'white',
  width,
  style,
}: {
  children: React.ReactNode;
  marginVertical?: ViewStyle['marginVertical'];
  padding?: ViewStyle['padding'];
  borderWidth?: ViewStyle['borderWidth'];
  borderStyle?: ViewStyle['borderStyle'];
  borderColor?: ViewStyle['borderColor'];
  width: ViewStyle['width'];
  style?: ViewStyle;
}) {
  return (
    <View
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
        marginVertical: marginVertical,
        padding,
        width,
        borderWidth,
        borderStyle,
        borderColor,
        ...style,
      }}
    >
      {children}
    </View>
  );
}
