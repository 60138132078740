import * as Sentry from '@sentry/browser';
import isWeb from '~/client/utils/isWeb';
import '~/client/nativewind-output';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://7683445c423c4de28e04eed14728bc16@o230997.ingest.sentry.io/1390502',
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import splash from '~/client/splash';
import React from 'react';
import { AppRegistry, Appearance } from 'react-native';

import ThemeContext, {
  ColorSchemeName,
  SetColorScheme,
} from '~/client/ThemeContext';
import App from '~/client/App';

// @ts-expect-error `module.hot` is globally available since we're using webpack hot middleware
if (module.hot) {
  // @ts-expect-error `module.hot.accept` exists since we're using webpack hot middleware
  module.hot.accept();
}

class AppWrapper extends React.Component<
  any,
  {
    colorScheme: ColorSchemeName;
    setColorScheme: SetColorScheme;
  }
> {
  setColorScheme: SetColorScheme;

  constructor(props: any) {
    super(props);

    this.setColorScheme = (a: ColorSchemeName) => {
      this.setState((state) => ({
        colorScheme: a,
      }));
    };

    this.state = {
      colorScheme: Appearance.getColorScheme() || 'dark',
      setColorScheme: this.setColorScheme,
    };
  }

  render() {
    return (
      <ThemeContext.Provider
        value={{
          colorScheme: this.state.colorScheme,
          // @ts-expect-error
          setColorScheme: this.setColorScheme,
        }}
      >
        <App />
      </ThemeContext.Provider>
    );
  }
}

AppRegistry.registerComponent('Streameater', () => AppWrapper);
if (isWeb()) {
  AppRegistry.runApplication('Streameater', {
    rootTag: document.getElementById('app'),
  });
}
