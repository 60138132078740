import type { PASSWORD_VALIDATION_STATUSES } from '~/server/services/AuthenticationService';

// much of this is copied from the server.
// TODO: find a place for shared constants.
export enum Services {
  apple = 'apple',
  spotify = 'spotify',
  youtube = 'youtube',
  unknown = 'unknown',
}

export enum UrlTypes {
  song = 'song',
  album = 'album',
  playlist = 'playlist',
}

export const APP_NAME = '⛳️ Links Club';
export const BASE_DOMAIN = 'music.links.club';
export const BASE_URL = `https://${BASE_DOMAIN}`;
export const CUSTOM_URL_SCHEME = 'streameater://';
export const CONTACT_EMAIL_ADDRESS = `hello@${BASE_DOMAIN}`;

export const PASSWORD_VALIDATION_ERROR_MESSAGES = new Map<
  keyof typeof PASSWORD_VALIDATION_STATUSES,
  string
>([
  [
    'TOO_SHORT',
    'Your password is too short. Please provide a longer password.',
  ],
  [
    'LOW_COMPLEXITY',
    'Password must contain a combination of upper and lower-case characters. Please try another password.',
  ],
  [
    'CONFIRMATION_MISMATCH',
    'Password fields must match. Please enter the same password in both fields.',
  ],
]);
