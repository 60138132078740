import React from 'react';
import { View } from 'react-native';

import SongLink from '~/client/components/SongLink';
import TitleBox from '~/client/components/TitleBox';
import { RecentSubmission } from '~/client/types';

export function RecentSongs({
  recentSongs,
}: {
  recentSongs: RecentSubmission[];
}) {
  return (
    <TitleBox title="Recent Songs">
      {recentSongs.map((song) => (
        <View className="mb-4" key={song.id}>
          <SongLink
            id={song.id}
            artist_name={song.artist_name}
            song_name={song.song_name}
            artwork_url={song.artwork_url}
          />
        </View>
      ))}
    </TitleBox>
  );
}
