import React, { forwardRef, ForwardedRef, useState } from 'react';
import { View, Pressable, Modal, Button } from 'react-native';
import { Link } from '@react-navigation/native';

import { APP_NAME } from '~/client/utils/constants';
import XIcon from '~/client/images/XIcon';
import TextInput from '~/client/components/TextInput';
import Error from '~/client/components/Error';
import Text from '~/client/components/Text';
import { fetchFromServer } from '~/client/utils/fetchJSONFromServer';

interface LoginProps {
  handleClickOutside: (e: Event) => void;
  handleClickedLogin: () => void;
  handleDismiss: () => void;
  visible: boolean;
}

const Login = forwardRef<View, LoginProps>(
  (props: LoginProps, ref: ForwardedRef<View>) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);

    const handleLogin = async () => {
      setError(null);

      if (!email.length || !password.length) {
        setError('Please enter your credentials.');
        return;
      }
      const response = await fetchFromServer(`/login`, {
        method: 'POST',
        body: JSON.stringify({
          username: email,
          password: password,
        }),
      });
      if (response.status < 400) {
        window.location.href = '/';
        return;
      }

      if (response.status === 401) {
        setError(
          'Invalid login or password. Please check your credentials and try again.',
        );
      } else {
        setError(
          'Unexpected error. Please try again, or contact us if the error persists.',
        );
      }
    };

    return (
      <Modal animationType="none" transparent={true} visible={props.visible}>
        <View
          className="w-[90%] h-[60%] md:max-w-2xl md:h-80 m-auto p-4 bg-white drop-shadow rounded-sm dark:bg-slate-700 flex justify-between z-10 relative"
          ref={ref}
        >
          <Pressable
            className="absolute top-0 right-0 p-4 z-10"
            onPress={props.handleDismiss}
          >
            <XIcon
              width={10}
              height={10}
              className="fill-slate-800 dark:fill-white"
            />
          </Pressable>
          <View className="h-full p-8">
            <Error errorText={error} />
            <TextInput
              id="emailAddress"
              name="emailAddress"
              placeholder="email address"
              type="text"
              onChange={({ nativeEvent: { text } }) => setEmail(text)}
            />
            <TextInput
              id="password"
              name="password"
              placeholder="password"
              type="password"
              onChange={({ nativeEvent: { text } }) => setPassword(text)}
              onSubmitEditing={handleLogin}
            />

            <Button title="Login" onPress={handleLogin} />

            <View className="mt-4">
              <Link
                to={{ screen: 'ForgotPassword' }}
                className="text-slate-800 dark:text-white"
              >
                <Text>Click here to reset your password.</Text>
              </Link>
            </View>
            <View className="mt-4">
              <Link
                to={{ screen: 'StartSignup' }}
                className="text-slate-800 dark:text-white"
              >
                <Text>New to Music {APP_NAME}? Sign Up here.</Text>
              </Link>
            </View>
          </View>
        </View>
        <View className="h-screen w-screen opacity-50 absolute bg-slate-200 dark:bg-slate-800" />
      </Modal>
    );
  },
);

export default Login;
