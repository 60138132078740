import User from '~/server/models/User';

import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import fetchJSONFromServer from '~/client/utils/fetchJSONFromServer';

interface LoggedInProviderProps {
  children: ReactNode;
}
export const LoggedInContext = createContext<Partial<User>>({});

export const LoggedInProvider = (
  props: PropsWithChildren<LoggedInProviderProps>,
) => {
  const [loggedInUser, setLoggedInUser] = useState<User>();
  useEffect(() => {
    const fetchUser = async () => {
      const responseBody = await fetchJSONFromServer('/user');

      setLoggedInUser(responseBody);
    };

    fetchUser();
  }, []);

  return (
    <LoggedInContext.Provider value={loggedInUser!}>
      {props.children}
    </LoggedInContext.Provider>
  );
};

export const useLoggedIn = () => {
  const ctx = useContext(LoggedInContext);
  if (!ctx) {
    return;
  }

  return ctx;
};
