import React, { useState } from 'react';
import { View, Button } from 'react-native';

import TextInput from '~/client/components/TextInput';
import ErrorComponent from '~/client/components/Error';
import fetchJSONFromServer from '~/client/utils/fetchJSONFromServer';

export default function ForgotPassword() {
  const [email, setEmail] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async () => {
    if (!email) {
      setError('You must enter an email address.');
    }
    const response = await fetchJSONFromServer(`/auth/password/reset`, {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    });

    await response.json();
  };

  return (
    <View className="mt-16 p-4 mb-4 bg-white drop-shadow rounded-sm dark:bg-gray-800">
      <View>
        <View>
          <ErrorComponent errorText={error} />
          <TextInput
            id="email"
            name="email"
            placeholder={'Enter Your Email'}
            onChange={({ nativeEvent: { text } }) => setEmail(text)}
            type="email"
          />
          <Button
            title="Request New Password"
            disabled={email == null || !email.length}
            onPress={handleSubmit}
          />
        </View>
      </View>
    </View>
  );
}
