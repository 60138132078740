import React, { useState, useEffect } from 'react';
import { Link as NavigationLink } from '@react-navigation/native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  View,
  Appearance,
  Dimensions,
  ViewStyle,
  StyleProp,
} from 'react-native';
import twColors from 'tailwindcss/colors';
import { InferAttributes } from 'sequelize';

import ActivityIndicator from '~/client/components/ActivityIndicator';
import AlbumArtwork from '~/server/models/AlbumArtwork';
import Box from '~/client/components/Box';
import CompactSongLink from '~/client/components/CompactSongLink';
import Text from '~/client/components/Text';
import Link from '~/client/components/Link';
import Image from '~/client/components/Image';
import collectMediaLinks from '~/client/utils/collectMediaLinks';
import ServiceIcon from '~/client/components/ServiceIcon';
import { CONTACT_EMAIL_ADDRESS } from '~/client/utils/constants';
import useTextColor from '~/client/hooks/useTextColor';
import useBackgroundColor from '~/client/hooks/useBackgroundColor';
import fetchJSONFromServer from '~/client/utils/fetchJSONFromServer';

import type { RootStackParamList } from '~/client/NavigationConfig';

interface Album {
  name: string;
  songs: {
    name: string;
    artist: string;
    song_id: string;
    song_name: string;
    artist_name: string;
    artwork_url: string;
    spotify_url: string;
    apple_url: string;
    youtube_id: string;
    track_number: number;
    disc_number: number;
  }[];
  artwork_url: string;
  apple_url: string;
  spotify_url: string;
  disc_count: number;
  album_art?: Omit<InferAttributes<AlbumArtwork>, 'id' | 'album_id'>;
}

export default function Album({
  route: {
    params: { resourceId },
  },
}: NativeStackScreenProps<RootStackParamList, 'Album'>) {
  const [albumData, setAlbumData] = useState<Album>();
  const [imageSize, setImageSize] = useState(400);
  const defaultTextColor = useTextColor();
  const defaultBgColor = useBackgroundColor();

  useEffect(() => {
    const fetchData = async () => {
      const responseBody = await fetchJSONFromServer(`/album/${resourceId}`);

      setAlbumData(responseBody);
    };

    fetchData();
  }, []);

  if (albumData == null) {
    return <ActivityIndicator />;
  }

  document.title = albumData.name;

  const albumUrls = collectMediaLinks({
    spotifyUrl: albumData.spotify_url,
    appleUrl: albumData.apple_url,
  });

  let { album_art } = albumData;
  const colorScheme = Appearance.getColorScheme();
  const backgroundBlendColor =
    colorScheme === 'dark' ? twColors.gray['800'] : 'white';

  if (!album_art) {
    album_art = {
      artwork_url: albumData.artwork_url,
      bg_color: defaultBgColor.slice(1),
      text_color_1: defaultTextColor.slice(1),
      text_color_2: defaultTextColor.slice(1),
      text_color_3: defaultTextColor.slice(1),
      text_color_4: defaultTextColor.slice(1),
    };
  }

  const baseStyling = {
    background: `linear-gradient(to right, ${backgroundBlendColor}, ${backgroundBlendColor}),
  linear-gradient(to right, #${album_art.bg_color}, #${album_art.bg_color})
  `,
    backgroundBlendMode: 'soft-light',
  };

  return (
    <>
      <View
        className="flex flex-col p-9 mt-16 mb-4 text-sm rounded drop-shadow"
        style={baseStyling as StyleProp<ViewStyle>}
        onLayout={() => {
          setImageSize(
            Math.min(Math.floor(Dimensions.get('window').width - 40), 400),
          );
        }}
      >
        <View className="mb-8">
          <NavigationLink to={{ screen: 'Album', params: { resourceId } }}>
            <Text
              style={{ color: `#${album_art.text_color_1}`, fontSize: '20px' }}
            >
              {albumData.name}
            </Text>
          </NavigationLink>
        </View>

        <View className="self-center md:self-start">
          <Image
            src={albumData.artwork_url
              .replace('{w}', String(imageSize))
              .replace('{h}', String(imageSize))}
            style={{
              width: imageSize,
              height: imageSize,
              borderRadius: '0.25rem',
            }}
          />
        </View>

        <Box width={300}>
          {albumUrls.length ? (
            <>
              <Text
                style={{
                  color: `#${album_art.text_color_1}`,
                  fontSize: '1.1rem',
                  marginRight: '0.5rem',
                }}
              >
                Listen on
              </Text>
              {albumUrls.map(({ service, url }) => (
                <ServiceIcon
                  key={service}
                  songUrl={url}
                  service={service}
                  style={{ marginRight: '1rem' }}
                />
              ))}
            </>
          ) : (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Link href={`mailto:${CONTACT_EMAIL_ADDRESS}`}>
                <Text>No Album URLs found. Tap here to Contact us!</Text>
              </Link>
            </View>
          )}
        </Box>

        {/* single disc view */}
        {albumData && Number(albumData.disc_count) === 1
          ? albumData.songs.map(
              ({
                song_id,
                name,
                artist_name,
                spotify_url,
                apple_url,
                youtube_id,
                track_number,
              }) => (
                <View style={{ marginBottom: '1rem' }} key={song_id}>
                  <CompactSongLink
                    id={song_id}
                    key={song_id}
                    artist_name={artist_name}
                    track_number={track_number}
                    song_name={name}
                    songUrls={collectMediaLinks({
                      spotifyUrl: spotify_url,
                      appleUrl: apple_url,
                      youtubeId: youtube_id,
                    })}
                    options={
                      album_art?.text_color_1
                        ? { textColor: album_art.text_color_1 }
                        : undefined
                    }
                  />
                </View>
              ),
            )
          : // multiple disc view
            [...Array(Number(albumData.disc_count)).keys()].map((discIndex) => {
              return (
                <View key={discIndex}>
                  {discIndex > 0 ? (
                    <View
                      style={{
                        marginBottom: '0.75rem',
                        borderBottomColor: `#${album_art?.text_color_2}`,
                        borderBottomWidth: 1,
                      }}
                    />
                  ) : null}
                  <View key={discIndex}>
                    {albumData?.songs
                      .filter((song) => song.disc_number - 1 === discIndex)
                      .map(
                        ({
                          song_id,
                          name,
                          artist_name,
                          spotify_url,
                          apple_url,
                          youtube_id,
                          track_number,
                        }) => (
                          <View style={{ marginBottom: '1rem' }} key={song_id}>
                            <CompactSongLink
                              id={song_id}
                              key={song_id}
                              artist_name={artist_name}
                              track_number={track_number}
                              song_name={name}
                              songUrls={collectMediaLinks({
                                spotifyUrl: spotify_url,
                                appleUrl: apple_url,
                                youtubeId: youtube_id,
                              })}
                              options={
                                album_art?.text_color_1
                                  ? { textColor: album_art?.text_color_1 }
                                  : undefined
                              }
                            />
                          </View>
                        ),
                      )}
                  </View>
                </View>
              );
            })}
      </View>
    </>
  );
}
