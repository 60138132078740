import React from 'react';
import { View, ViewStyle } from 'react-native';

import { Services } from '~/client/utils/constants';
import mapServiceTypesToIcons from '~/client/utils/mapServiceTypesToIcons';
import Link from '~/client/components/Link';

export default function ServiceIcon({
  songUrl,
  service,
  size = 32,
  style,
}: {
  songUrl: string;
  service: Services;
  size?: number;
  style?: ViewStyle;
}) {
  return (
    <Link href={songUrl} target="_blank">
      <View style={style}>{mapServiceTypesToIcons(service, size)}</View>
    </Link>
  );
}
