import React from 'react';
import { Text as ReactText, useColorScheme } from 'react-native';

export default function Text(props: {
  style?: object;
  className?: string;
  children: React.ReactNode;
}) {
  const colorScheme = useColorScheme();

  return (
    <ReactText
      style={{
        color: colorScheme === 'light' ? 'black' : 'white',
        fontSize: 16,
        ...props.style,
      }}
    >
      {props.children}
    </ReactText>
  );
}
