import React, { useState, useEffect } from 'react';
import { View, ScrollView, Platform, RefreshControl } from 'react-native';

import ActionBar from '~/client/components/ActionBar';

import { ArtworkResourceList } from '~/client/components/ArtworkResourceList';
import { RecentSongs } from '~/client/components/Home/RecentSongs';
import { APP_NAME } from '~/client/utils/constants';
import { RecentSubmission } from '~/client/types';
import { useLoggedIn } from '~/client/utils/LoggedInContext';
import isWeb from '~/client/utils/isWeb';
import fetchJSONFromServer from '~/client/utils/fetchJSONFromServer';

export default function Home() {
  const [recentAlbums, setRecentAlbums] = useState<RecentSubmission[]>([]);
  const [recentAlbumsNeedsRefresh, setRecentAlbumsNeedsRefresh] =
    useState(false);
  const [recentCompleteAlbums, setRecentCompleteAlbums] = useState<
    RecentSubmission[]
  >([]);
  const [recentPlaylists, setRecentPlaylists] = useState<RecentSubmission[]>(
    [],
  );
  const [userPlaylists, setUserPlaylists] = useState<RecentSubmission[]>([]);
  const [refreshing, setRefreshing] = React.useState(false);
  const loggedInUser = useLoggedIn();

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);
      const responseBody = await fetchJSONFromServer(
        `/recentAlbums?${Date.now()}`,
      );

      setRecentAlbums(responseBody);
      setRecentAlbumsNeedsRefresh(false);
      setRefreshing(false);
    };

    fetchData();
  }, [recentAlbumsNeedsRefresh]);

  useEffect(() => {
    const fetchData = async () => {
      const responseBody = await fetchJSONFromServer(
        `/recentCompleteAlbums?${Date.now()}`,
      );

      setRecentCompleteAlbums(responseBody);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (loggedInUser?.email) {
        const responseBody = await fetchJSONFromServer(
          `/myPlaylists?${Date.now()}`,
        );

        setUserPlaylists(responseBody);
      }
    };

    fetchData();
  }, [loggedInUser]);

  useEffect(() => {
    const fetchData = async () => {
      const responseBody = await fetchJSONFromServer(
        `/recentPlaylists?${Date.now()}`,
      );

      setRecentPlaylists(responseBody);
    };

    fetchData();
  }, []);

  if (isWeb()) {
    document.title = APP_NAME;
  }

  const WrapperElement = isWeb() ? View : ScrollView;

  return (
    <>
      <WrapperElement
        style={{ flex: 1 }}
        className="mt-10"
        refreshControl={
          Platform.OS === 'web' ? undefined : (
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                setRecentAlbumsNeedsRefresh(true);
              }}
            />
          )
        }
      >
        <ActionBar recentAlbums={recentAlbums} />
        <View className="mb-36" />
        {loggedInUser?.email ? (
          <ArtworkResourceList
            title="My Playlists"
            resourceList={userPlaylists}
            type={'Playlist'}
          />
        ) : null}
        <ArtworkResourceList
          title="Recent Playlists"
          resourceList={recentPlaylists}
          type={'Playlist'}
        />
        <ArtworkResourceList
          title="Recent Albums"
          type={'Album'}
          resourceList={recentCompleteAlbums}
        />
        <RecentSongs recentSongs={recentAlbums} />
      </WrapperElement>
    </>
  );
}
