import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      viewBox="0 0 1200 1200"
      width={props.width}
      height={props.width}
      {...props}
      style={{ borderRadius: 10 }}
    >
      <Path fill="#FA4058" d="M0 0h1200v1200H0z" />
      <Path
        fill="#FFFFFF"
        d="M778.6 295.1c-2.1.2-21.5 3.6-23.8 4.1l-266.5 53.9c-7 1.5-12.5 4-16.7 7.5-5.1 4.3-8 10.3-9 17.3-.3 1.5-.6 4.6-.6 9v334c0 3.8 0 7.2-1 11a25 25 0 01-13.8 16.8 44.7 44.7 0 01-10.7 3.1l-17.4 3.5c-22.1 4.5-37.4 7.5-50.5 12.5a78.2 78.2 0 00-29.2 18.7 69.5 69.5 0 00-18 55.7 67.5 67.5 0 0054 61c13.4 2.5 27.6 1.6 48.3-2.6 11-2.2 21.4-5.6 31.3-11.4a79.1 79.1 0 0038-53.4c2.2-10.9 3.3-22 3.2-33V514.3c0-15.6 4.1-19.7 16.7-22.7l233-46.8c14.6-2.8 20.8 1.4 20.8 16.5v199.1c0 3.8.3 7.2-.6 11-1 3.7-1.8 7.1-4.3 10-2.4 3-5.4 5.3-8.8 6.9a44.8 44.8 0 01-10.7 3.1L725 695c-22.1 4.5-37.2 7.5-50.2 12.5a78 78 0 00-29.2 18.8 70.8 70.8 0 00-19.2 55.8 70.8 70.8 0 0022.7 44.5c9.1 8.4 20.4 14.1 32.5 16.5 13.3 2.7 27.5 1.7 48.2-2.4 11-2.3 21.4-5.6 31.3-11.4a78.4 78.4 0 0037.7-53.6c2.4-11.4 2.3-21.7 2.3-33V318.6c0-15.4-8-24.8-22.4-23.6"
      />
    </Svg>
  );
}

export default SvgComponent;
