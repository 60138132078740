import React from 'react';
import { Image } from 'react-native';
import { baseUrl } from '~/client/utils/fetchJSONFromServer';

const IMAGE_NOT_FOUND_URL = `${baseUrl}/image-not-found.svg`;

export default function ({ src, style, ...props }: any) {
  const imageSrc = src?.length ? src : IMAGE_NOT_FOUND_URL;
  const [imageUrl, setImageUrl] = React.useState<string>(imageSrc);

  return (
    <Image
      source={{
        uri: imageUrl,
      }}
      onError={({ nativeEvent }) => {
        setImageUrl(IMAGE_NOT_FOUND_URL);
      }}
      style={style}
      {...props}
    />
  );
}
