import React from 'react';
import { View, Text } from 'react-native';

export default function Error({ errorText }: { errorText: string | null }) {
  if (!errorText) {
    return null;
  }

  return (
    <View className="my-3">
      <Text className="text-red-700 font-bold">{errorText} </Text>
    </View>
  );
}
