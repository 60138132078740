import React from 'react';
import { View, Text } from 'react-native';

import { Link } from '@react-navigation/native';
import Image from '~/client/components/Image';

export interface SongLink {
  artist_name: string;
  song_name: string;
  id: string;
  artwork_url: string;
  href?: string;
}

export default function SongLink({
  artist_name,
  song_name,
  id,
  artwork_url,
  href,
}: SongLink) {
  return (
    <View key={id} className="flex flex-row">
      <Link
        to={{ screen: 'Song', params: { resourceId: id } }}
        style={{ width: '100%' }}
      >
        <View key={id} className="flex flex-row items-center">
          <Image
            src={artwork_url.replace('{w}', '40').replace('{h}', '40')}
            style={{ width: 40, height: 40 }}
            className="mr-4"
          />
          <Text numberOfLines={1} className="text-slate-700 dark:text-white">
            {artist_name} - {song_name}
          </Text>
        </View>
      </Link>
    </View>
  );
}
