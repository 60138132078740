import React, { useState, useEffect } from 'react';
import { View, Dimensions } from 'react-native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';

import ActivityIndicator from '~/client/components/ActivityIndicator';
import AppleMusicIcon from '~/client/images/AppleMusicIcon';
import YouTubeIcon from '~/client/images/YouTubeIcon';
import SpotifyIcon from '~/client/images/SpotifyIcon';
import TitleText from '~/client/components/TitleText';
import Text from '~/client/components/Text';
import Link from '~/client/components/Link';
import Image from '~/client/components/Image';
import type { RootStackParamList } from '~/client/NavigationConfig';
import fetchJSONFromServer from '~/client/utils/fetchJSONFromServer';

export default function Embed({
  route: {
    params: { resourceSourceUrl },
  },
}: NativeStackScreenProps<RootStackParamList, 'Embed'>) {
  const [songData, setSongData] = useState<{
    song: { name: string; id: number };
    artist: { name: string };
    spotify: { url: string };
    apple: { url: string };
    youtube: { youtube_id: string };
    album: { artwork_url: string };
    album_art: { artwork_url: string };
  } | null>(null);
  const [imageSize, setImageSize] = useState(120);
  const [iconSize, setIconSize] = useState(56);
  const [maxTextWidth, setMaxTextWidth] = useState<number | undefined>(
    undefined,
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const embeddableUrl = resourceSourceUrl;

      try {
        const responseBody = await fetchJSONFromServer(
          `/embed/${encodeURIComponent(embeddableUrl)}?${Date.now()}`,
        );

        setSongData(responseBody);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <ActivityIndicator />;
  }
  if (songData == null) {
    const [, embeddableUrl] =
      `${window.location.pathname}${window.location.search}`.split('/e/');

    return (
      <View>
        <Link href={'/'} target="_blank">
          <TitleText>{embeddableUrl}</TitleText>
        </Link>
      </View>
    );
  }

  const { spotify, apple, youtube } = songData;

  return (
    <View
      onLayout={() => {
        const windowWidth = Dimensions.get('window').width;
        if (windowWidth < 400) {
          setImageSize(windowWidth * 0.32);
          setIconSize(windowWidth * 0.1);
          setMaxTextWidth(windowWidth * 0.46);
        } else {
          setImageSize(120);
          setIconSize(56);
          setMaxTextWidth(undefined);
        }
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          paddingVertical: 20,
        }}
      >
        <View style={{ marginRight: 20 }}>
          <Link href={`/l/${songData.song.id}`} target="_blank">
            <Image
              src={songData.album_art.artwork_url
                .replace('{w}', String(imageSize))
                .replace('{h}', String(imageSize))}
              style={{
                width: imageSize,
                height: imageSize,
                borderRadius: '0.25rem',
              }}
              resizeMode="contain"
            />
          </Link>
        </View>
        <View>
          <Link href={`/l/${songData.song.id}`} target="_blank">
            <View style={{ flexDirection: 'column', maxWidth: maxTextWidth }}>
              <TitleText>{songData.song.name}</TitleText>
              <Text>by {songData.artist.name}</Text>
            </View>
          </Link>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingVertical: 20,
            }}
          >
            {apple ? (
              <View style={{ marginRight: iconSize * 0.35 }}>
                <Link href={apple.url} target="_blank">
                  <AppleMusicIcon width={iconSize} />
                </Link>
              </View>
            ) : null}
            {spotify ? (
              <View style={{ marginRight: iconSize * 0.35 }}>
                <Link href={spotify.url} target="_blank">
                  <SpotifyIcon width={iconSize} />
                </Link>
              </View>
            ) : null}

            {youtube ? (
              <View style={{ marginRight: iconSize * 0.35 }}>
                <Link
                  href={`https://youtu.be/${youtube.youtube_id}`}
                  target="_blank"
                >
                  <YouTubeIcon width={iconSize} />
                </Link>
              </View>
            ) : null}
          </View>
        </View>
      </View>
    </View>
  );
}
