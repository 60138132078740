import React from 'react';
import { View, Text } from 'react-native';

export default function TitleBox({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <View className="my-4 p-4 bg-white drop-shadow rounded-sm dark:bg-gray-800">
      <Text className="dark:text-white text-sm mb-8 font-bold">{title}</Text>
      {children}
    </View>
  );
}
