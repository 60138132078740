import React from 'react';
import { View } from 'react-native';

export default function Link({
  href,
  target,
  children,
  ...props
}: {
  href: string;
  target?: string;
  children: React.ReactNode;
  style?: {
    color?: string;
  };
}) {
  return (
    <View
      style={{
        // @ts-expect-error cursor style is only a thing in react-native-web, which is under-typed.
        cursor: 'pointer',
      }}
      {...props}
    >
      <a
        href={href}
        target={target}
        style={{
          display: 'flex',
          width: '100%',
          color: props.style?.color,
          alignItems: 'center',
        }}
      >
        {children}
      </a>
    </View>
  );
}
