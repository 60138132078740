import React from 'react';
import { View, Text } from 'react-native';
import { Link } from '@react-navigation/native';

import UserMenu from './UserMenu';
import { useLoggedIn } from '~/client/utils/LoggedInContext';
import isWeb from '~/client/utils/isWeb';
import { APP_NAME } from '~/client/utils/constants';

export default function Nav() {
  const loggedInUser = useLoggedIn();

  if (
    isWeb() &&
    (window.location.pathname.match('/e/') ||
      window.location.pathname.match('/waiting/'))
  ) {
    return null;
  }

  return (
    <View className="flex flex-row justify-between items-center w-full h-14 z-10 border-b border-b-neutral-200 dark:border-b-gray-900 bg-white dark:bg-gray-800">
      <View className="ml-4">
        <Link
          to={{ screen: 'Home' }}
          className="text-slate-800 dark:text-white"
        >
          <Text
            className="text-slate-700 dark:text-white"
            style={{ fontSize: 16 }}
          >
            {APP_NAME}
          </Text>
        </Link>
      </View>
      <UserMenu user={loggedInUser?.email ? loggedInUser : undefined} />
    </View>
  );
}
