import React, { useState, useEffect } from 'react';
import {
  View,
  Dimensions,
  Appearance,
  ViewStyle,
  StyleProp,
  Pressable,
} from 'react-native';
import twColors from 'tailwindcss/colors';
import { InferAttributes } from 'sequelize';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Link } from '@react-navigation/native';

import AlbumArtwork from '~/server/models/AlbumArtwork';
import AppleMusicIcon from '~/client/images/AppleMusicIcon';
import YouTubeIcon from '~/client/images/YouTubeIcon';
import SpotifyIcon from '~/client/images/SpotifyIcon';
import ExternalLink from '~/client/components/Link';
import Image from '~/client/components/Image';
import Text from '~/client/components/Text';
import useTextColor from '~/client/hooks/useTextColor';
import useBackgroundColor from '~/client/hooks/useBackgroundColor';
import { useLoggedIn } from '~/client/utils/LoggedInContext';

import logger from '~/client/utils/logger';
import type { RootStackParamList } from '~/client/NavigationConfig';
import fetchJSONFromServer from '~/client/utils/fetchJSONFromServer';

export default function Song({
  route: {
    params: { resourceId },
  },
}: NativeStackScreenProps<RootStackParamList, 'Song'>) {
  const loggedInUser = useLoggedIn();
  const [songData, setSongData] = useState<{
    song: { name: string; id: number };
    artist: { name: string };
    spotify: { url: string };
    apple: { url: string };
    youtube: { youtube_id: string };
    album: { artwork_url: string; name: string; id: string };
    album_art?: Omit<InferAttributes<AlbumArtwork>, 'id' | 'album_id'>;
  } | null>(null);

  const [isFavorite, setIsFavorite] = useState<boolean | null>(false);

  const [imageSize, setImageSize] = useState(400);
  const [padding, setPadding] = useState(9);
  const defaultTextColor = useTextColor();
  const defaultBgColor = useBackgroundColor();

  useEffect(() => {
    const fetchData = async () => {
      const responseBody = await fetchJSONFromServer(
        `/song/${resourceId}?${Date.now()}`,
      );

      setSongData(responseBody);

      setIsFavorite(responseBody.isUserFavorite);
    };

    fetchData();
  }, []);

  if (!songData) {
    return null;
  }

  const { song, spotify, apple, youtube, album } = songData;
  let { album_art } = songData;

  if (!album_art) {
    album_art = {
      artwork_url: songData.album.artwork_url,
      bg_color: defaultBgColor.slice(1),
      text_color_1: defaultTextColor.slice(1),
      text_color_2: defaultTextColor.slice(1),
      text_color_3: defaultTextColor.slice(1),
      text_color_4: defaultTextColor.slice(1),
    };
  }

  if (!apple && !spotify) {
    logger.error('no apple or spotify song found. this should never happen...');
  }

  const colorScheme = Appearance.getColorScheme();
  const backgroundBlendColor =
    colorScheme === 'dark' ? twColors.gray['800'] : twColors.slate['50'];

  const baseStyling = {
    background: `linear-gradient(to right, ${backgroundBlendColor}, ${backgroundBlendColor}),
  linear-gradient(to right, #${album_art.bg_color}, #${album_art.bg_color})
  `,
    backgroundBlendMode: 'soft-light',
  };

  return (
    <>
      <View
        style={baseStyling as StyleProp<ViewStyle>}
        className={`flex flex-col p-${padding} mt-16 mb-4 text-sm rounded drop-shadow`}
        onLayout={() => {
          const windowWidth = Dimensions.get('window').width;
          setImageSize(Math.min(Math.floor(windowWidth - 60), 400));
          if (windowWidth < 420) {
            setPadding(4);
          } else {
            setPadding(9);
          }
        }}
      >
        <View className="mb-8">
          <Text style={{ color: `#${album_art.text_color_1}` }}>
            {songData.artist.name}
          </Text>
          <Link
            to={{ screen: 'Song', params: { resourceId: songData.song.id } }}
          >
            <Text
              style={{
                color: `#${album_art.text_color_1}`,
                fontWeight: 'bold',
              }}
            >
              {song.name}
            </Text>
          </Link>
        </View>
        <Link
          to={{ screen: 'Album', params: { resourceId: songData.album.id } }}
        >
          <View>
            <Image
              className="rounded"
              src={album.artwork_url
                .replace('{w}', String(imageSize))
                .replace('{h}', String(imageSize))}
              style={{
                width: imageSize,
                height: imageSize,
              }}
              resizeMode="contain"
            />

            <Text style={{ color: `#${album_art.text_color_2}` }}>
              {songData.album.name}
            </Text>
          </View>
        </Link>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 20,
          }}
        >
          {apple ? (
            <View style={{ marginRight: 20 }}>
              <ExternalLink href={apple.url} target="_blank">
                <AppleMusicIcon width="56" />
              </ExternalLink>
            </View>
          ) : null}
          {spotify ? (
            <View style={{ marginRight: 20 }}>
              <ExternalLink href={spotify.url} target="_blank">
                <SpotifyIcon width={56} />
              </ExternalLink>
            </View>
          ) : null}

          {youtube ? (
            <View style={{ marginRight: 20 }}>
              <ExternalLink
                href={`https://youtu.be/${youtube.youtube_id}`}
                target="_blank"
              >
                <YouTubeIcon width={56} />
              </ExternalLink>
            </View>
          ) : null}
        </View>
        <Pressable
          onPress={async () => {
            if (!loggedInUser?.email) {
              window.alert('Please log in to use Favorites.');
              return;
            }

            await fetchJSONFromServer(`/favorite/${songData.song.id}`, {
              method: 'POST',
              body: JSON.stringify({ favorite: !isFavorite }),
            });

            setIsFavorite(!isFavorite);
          }}
        >
          <View
            // @ts-expect-error
            style={{ cursor: 'pointer' }}
          >
            <Text style={{ color: `#${album_art.text_color_1}` }}>
              {isFavorite ? '★ Unfavorite' : '☆ Favorite'}
            </Text>
          </View>
        </Pressable>
      </View>
    </>
  );
}
