import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgComponent(props: SvgProps & { width?: number }) {
  const width = props.width ?? 71;

  return (
    <Svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 71.412 50"
      width={width}
      height={width * 0.7}
      {...props}
    >
      <G id="prefix__g5" transform="scale(.58824)">
        <Path
          d="M118.9 13.3c-1.4-5.2-5.5-9.3-10.7-10.7C98.7 0 60.7 0 60.7 0s-38 0-47.5 2.5C8.1 3.9 3.9 8.1 2.5 13.3 0 22.8 0 42.5 0 42.5s0 19.8 2.5 29.2C3.9 76.9 8 81 13.2 82.4 22.8 85 60.7 85 60.7 85s38 0 47.5-2.5c5.2-1.4 9.3-5.5 10.7-10.7 2.5-9.5 2.5-29.2 2.5-29.2s.1-19.8-2.5-29.3z"
          id="prefix__path7"
          fill="red"
        />
        <Path
          id="prefix__polygon9"
          fill="#fff"
          d="M80.2 42.5L48.6 24.3v36.4z"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
