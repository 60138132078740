import url from 'url';

import logger from '~/client/utils/logger';
import { Services, UrlTypes } from '~/client/utils/constants';

export const getUrlSource = (inputUrl: string): Services => {
  const APPLE = /.+apple\.com\//;
  const SPOTIFY = /.+spotify\.com\//;
  const YOUTUBE = /(youtube\.com|youtu\.be)\//;

  if (SPOTIFY.test(inputUrl)) {
    return Services.spotify;
  }

  if (APPLE.test(inputUrl)) {
    return Services.apple;
  }

  if (YOUTUBE.test(inputUrl)) {
    logger.warn(`youtube not implemented yet. url: ${inputUrl}`);
    return Services.youtube;
  }

  return Services.unknown;
};

export const getUrlType = (
  inputUrl: string,
): { type: UrlTypes; service: Services } => {
  const source = getUrlSource(inputUrl);
  const parsedUrl = url.parse(inputUrl, true);

  if (source === Services.unknown) {
    throw new Error(`Unknown input source. url: ${inputUrl}`);
  }

  if (source === Services.youtube) {
    return { type: UrlTypes.song, service: Services.youtube };
  }

  if (source === Services.apple) {
    const service = Services.apple;
    if (parsedUrl.query.i) {
      return { type: UrlTypes.song, service };
    }

    if (/\/playlist\//.test(inputUrl)) {
      return { type: UrlTypes.playlist, service };
    }

    return { type: UrlTypes.album, service };
  }

  if (parsedUrl.pathname == null) {
    throw new Error(
      `Could not get pathname for parsed url. input: ${inputUrl}`,
    );
  }

  if (source === 'spotify') {
    const service = Services.spotify;
    if (parsedUrl.pathname.split('/').includes('track')) {
      return { type: UrlTypes.song, service };
    }

    if (parsedUrl.pathname.split('/').includes('album')) {
      return { type: UrlTypes.album, service };
    }
    if (parsedUrl.pathname.split('/').includes('playlist')) {
      return { type: UrlTypes.playlist, service };
    }
  }

  throw new Error(`no url type found: ${inputUrl}`);
};
