import React from 'react';
import { Appearance, ColorSchemeName as RNColorSchemeName } from 'react-native';

export type ColorSchemeName = NonNullable<RNColorSchemeName>;
export type SetColorScheme = (a: ColorSchemeName) => void;

const colorScheme = Appearance.getColorScheme();

const ThemeContext = React.createContext({
  colorScheme,
  setColorScheme: (a: ColorSchemeName) => undefined,
});

export default ThemeContext;
