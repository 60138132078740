import React, { useState, useRef } from 'react';
import { findDOMNode } from 'react-dom';
import { Text, View, Pressable, GestureResponderEvent } from 'react-native';
// TODO: wrap this with a link
import { Link } from '@react-navigation/native';

import User from '~/server/models/User';
import isWeb from '~/client/utils/isWeb';
import fetchJSONFromServer from '~/client/utils/fetchJSONFromServer';

import LoginModal from './Login';
import SignupModal from './Signup';

interface UserMenuProps {
  user?: Partial<User>;
}

export default function UserMenu(props: UserMenuProps) {
  const [open, setOpen] = useState(false);
  const [loginModalVisible, setloginModalVisible] = useState(false);
  const loginModalRef = useRef<View>(null);
  const [signupModalVisible, setSignupModalVisible] = useState(false);
  const signupModalRef = useRef<View>(null);

  // TODO: ** this is to fix the issue with clicking a 1Password in-browser field button,
  // but haven't quite figured it out.
  // const isEventTargetPartOfApp = (t: EventTarget | null) => {
  //   console.log(document.getElementById('app')?.contains(t as Node));
  //   return document.getElementById('app')?.contains(t as Node);
  // };

  const handleClickUserMenu = (e: GestureResponderEvent) => {
    if (open === false && isWeb()) {
      document.body.addEventListener('click', handleClickOutsideUserMenu);
    } else {
      return;
    }
    setOpen(!open);
  };

  function handleClickOutsideUserMenu(): void {
    if (isWeb()) {
      document.body.removeEventListener('click', handleClickOutsideUserMenu);
    }
    setOpen(false);
  }

  const handleLogin = (e?: GestureResponderEvent) => {
    if (loginModalVisible === false && isWeb()) {
      document.body.addEventListener('click', handleClickOutsideLoginModal);
    } else {
      return;
    }
    setloginModalVisible(true);
  };

  function handleClickOutsideLoginModal(e: Event): void {
    const loginModalRefDomNode = findDOMNode(loginModalRef.current);
    if (loginModalRefDomNode?.contains(e.target as Node)) {
      return;
    } else {
      dismissLoginModal();
    }
  }

  function dismissLoginModal() {
    setloginModalVisible(false);
    if (isWeb()) {
      document.body.removeEventListener('click', handleClickOutsideLoginModal);
    }
  }

  const handleSignup = (e?: GestureResponderEvent) => {
    if (signupModalVisible === false) {
      document.body.addEventListener('click', handleClickOutsideSignupModal);
    } else {
      return;
    }
    setSignupModalVisible(true);
  };

  function handleClickOutsideSignupModal(e: Event): void {
    const signupModalRefDomNode = findDOMNode(signupModalRef.current);
    if (signupModalRefDomNode?.contains(e.target as Node)) {
      return;
    } else {
      dismissSignupModal();
    }
  }

  async function handleLogout() {
    const response = await fetchJSONFromServer('/logout', {
      method: 'POST',
    });

    if (response.redirected) {
      window.location.href = '/';
    }
  }

  function dismissSignupModal() {
    setSignupModalVisible(false);
    document.body.removeEventListener('click', handleClickOutsideSignupModal);
  }

  const menuBaseStyles =
    'absolute right-2 px-4 pt-2 pb-6 bg-white dark:bg-slate-700 drop-shadow';
  return (
    <>
      {props.user?.email ? (
        <Pressable onPress={handleClickUserMenu}>
          <View>
            <View className="mr-4">
              <Text className="text-fuchsia-300 dark:text-white">
                {props.user.email}
              </Text>
            </View>
          </View>

          {open && (
            <View className={menuBaseStyles}>
              <Pressable onPress={handleClickOutsideUserMenu}>
                <Text className="text-fuchsia-300 mb-4">
                  {props.user.email}
                </Text>
              </Pressable>
              <Link
                to={{ screen: 'Tagged', params: { tagName: 'favorites' } }}
                className="py-2"
              >
                <Text className="dark:text-white">★ Favorites</Text>
              </Link>
              <Pressable
                onPress={() => window.alert('Profile not implemented yet!')}
              >
                <Text className="py-2 dark:text-white">👤 Profile</Text>
              </Pressable>
              <Pressable onPress={handleLogout}>
                <Text className="py-2 dark:text-white">⎋ Logout</Text>
              </Pressable>
            </View>
          )}
        </Pressable>
      ) : (
        <>
          <View className="flex flex-row">
            <Pressable onPress={handleSignup}>
              <View>
                <Text className="text-purple-300 mr-4">Sign Up</Text>
              </View>
            </Pressable>
            <Pressable onPress={handleLogin}>
              <View>
                <Text className="mr-4 text-orange-300 dark:text-white">
                  Login
                </Text>
              </View>
            </Pressable>
          </View>

          <LoginModal
            visible={loginModalVisible}
            handleClickOutside={handleClickOutsideLoginModal}
            handleClickedLogin={handleLogin}
            handleDismiss={dismissLoginModal}
            ref={loginModalRef}
          />

          <SignupModal
            visible={signupModalVisible}
            handleClickOutside={handleClickOutsideSignupModal}
            handleClickedSignup={handleSignup}
            handleDismiss={dismissSignupModal}
            ref={signupModalRef}
          />
        </>
      )}
    </>
  );
}
