import { useContext } from 'react';
import twColors from 'tailwindcss/colors';

import ThemeContext from '~/client/ThemeContext';

export default function useTextColor() {
  const { colorScheme } = useContext(ThemeContext);

  // #334155 === `slate-700`
  const textColor = colorScheme === 'dark' ? 'white' : twColors.slate['700'];

  return textColor;
}
