import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList, ResourceTypes } from '~/client/NavigationConfig';
import logger from '~/client/utils/logger';

import { UrlTypes } from '~/client/utils/constants';

export default function navigateToResource({
  navigation,
  resourceType,
  resourceId,
}: {
  navigation: NativeStackNavigationProp<RootStackParamList, ResourceTypes>;
  resourceType: string;
  resourceId: string;
}): void {
  if (resourceType === UrlTypes.song) {
    return navigation.navigate('Song', {
      resourceId,
    });
  } else if (resourceType === UrlTypes.album) {
    return navigation.navigate('Album', {
      resourceId,
    });
  } else if (resourceType === UrlTypes.playlist) {
    return navigation.navigate('Playlist', {
      resourceId,
    });
  }

  logger.error('Navigation to resource failed. This should never happen.', {
    resourceType,
    resourceId,
  });

  return navigation.navigate('Home');
}

export function getNavigationParams({
  resourceType,
  resourceId,
}: {
  resourceType: ResourceTypes;
  resourceId: string;
}) {
  return {
    screen: resourceType,
    params: { resourceId },
  };
}
