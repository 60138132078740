import React, { useState } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import isURL from 'validator/lib/isURL';

import { RecentSubmission } from '~/client/types';
import { TAB_NAMES } from '~/client/components/ActionBar';
import TextInput from '~/client/components/TextInput';
import Caption from '~/client/components/Caption';
import Error from '~/client/components/Error';
import { fetchFromServer } from '~/client/utils/fetchJSONFromServer';
import { RootStackParamList } from '~/client/NavigationConfig';
import { UrlTypes } from '~/client/utils/constants';
import navigateToResource from '~/client/utils/navigateToResource';

export default function SubmitUrl({
  active,
  mostRecentSubmission,
}: {
  active: boolean;
  mostRecentSubmission: RecentSubmission;
}) {
  const navigation =
    useNavigation<
      NativeStackNavigationProp<
        RootStackParamList,
        'Song' | 'Album' | 'Playlist'
      >
    >();

  const [error, setError] = useState<string | null>(null);

  if (!active) {
    return null;
  }

  return (
    <View className="my-4 w-[93%] md:w-full">
      <Error errorText={error} />
      <TextInput
        // @ts-expect-error
        onSubmitEditing={async (event) => {
          const url = event.nativeEvent.text;
          if (isURL(url)) {
            setError(null);
            // should we extract a function here?
            const response = await fetchFromServer(`/s?s=${url}`);

            if (!response.ok) {
              return navigation.navigate('Waiting', { resourceSourceUrl: url });
            }

            const responseData: {
              action: string;
              url: string;
              resourceId: string;
              resourceSourceUrl?: string;
              type: UrlTypes;
            } = await response.json();

            if (responseData.url) {
              return navigateToResource({
                navigation,
                resourceType: responseData.type,
                resourceId: responseData.resourceId,
              });
            } else {
              return navigation.navigate('Waiting', {
                resourceSourceUrl: responseData.resourceSourceUrl as string,
              });
            }
          } else {
            setError('Please enter a valid URL.');
          }
        }}
        id={TAB_NAMES.SUBMIT_URL}
        type="url"
        name="s"
        placeholder={`e.g. ${
          mostRecentSubmission?.spotify_song_url ??
          'https://music.apple.com/us/album/on-everything-instrumental/1445941371?i=1445941554'
        }`}
      />
      <Caption>Paste a link from a music service.</Caption>
    </View>
  );
}
