import React from 'react';
import { Pressable, Linking, View } from 'react-native';

import Text from '~/client/components/Text';

export default function Terms() {
  return (
    <View>
      <View style={{ marginTop: '1em', marginBottom: '1em' }}>
        <Text>
          This website utilizes the YouTube API to get YouTube links for songs.
        </Text>
      </View>
      <Pressable
        onPress={() => Linking.openURL('https://www.youtube.com/t/terms')}
      >
        <Text
          style={{
            textDecorationLine: 'underline',
            cursor: 'pointer',
          }}
        >
          YouTube's Terms of Service
        </Text>
      </Pressable>
      <Pressable
        onPress={() => Linking.openURL('https://policies.google.com/privacy')}
      >
        <Text
          style={{
            textDecorationLine: 'underline',
            cursor: 'pointer',
          }}
        >
          Google's Terms of Service
        </Text>
      </Pressable>
    </View>
  );
}
