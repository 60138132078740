import { useContext } from 'react';
import twColors from 'tailwindcss/colors';

import ThemeContext from '~/client/ThemeContext';

export default function useTextColor() {
  const { colorScheme } = useContext(ThemeContext);

  // #172438 === 'gray-900'
  return colorScheme === 'dark' ? twColors.gray['900'] : twColors.slate['50'];
}
