import React, { useState, useEffect } from 'react';
import { View, Dimensions } from 'react-native';
import { Link as NavigationLink } from '@react-navigation/native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';

import ActivityIndicator from '~/client/components/ActivityIndicator';
import Box from '~/client/components/Box';
import CompactSongLink from '~/client/components/CompactSongLink';
import Link from '~/client/components/Link';
import Image from '~/client/components/Image';
import Text from '~/client/components/Text';
import TitleText from '~/client/components/TitleText';
import { Services } from '~/client/utils/constants';
import mapServiceTypesToIcons from '~/client/utils/mapServiceTypesToIcons';
import collectMediaLinks from '~/client/utils/collectMediaLinks';
import type { RootStackParamList } from '~/client/NavigationConfig';
import fetchJSONFromServer from '~/client/utils/fetchJSONFromServer';

interface Playlist {
  name: string;
  service: Services;
  songs: {
    name: string;
    artist: string;
    id: string;
    song_name: string;
    artist_name: string;
    artwork_url: string;
    spotify_url: string;
    apple_url: string;
    youtube_id: string;
  }[];
  artwork_url?: string;
  url: string;
}

export default function Playlist({
  route: {
    params: { resourceId },
  },
}: NativeStackScreenProps<RootStackParamList, 'Playlist'>): React.ReactElement {
  const [playlistData, setPlaylistData] = useState<Playlist>();
  const [imageSize, setImageSize] = useState(400);

  useEffect(() => {
    const fetchData = async () => {
      const responseBody = await fetchJSONFromServer(`/playlist/${resourceId}`);

      setPlaylistData(responseBody);
    };

    fetchData();
  }, []);

  if (playlistData == null) {
    return <ActivityIndicator />;
  }

  document.title = playlistData.name;

  return (
    <>
      <View className="p-4 mt-16 mb-4 bg-white drop-shadow rounded-sm dark:bg-gray-800">
        <View className="mb-8">
          <NavigationLink to={{ screen: 'Playlist', params: { resourceId } }}>
            <TitleText>{playlistData.name}</TitleText>
          </NavigationLink>
        </View>

        <View
          onLayout={() => {
            setImageSize(
              Math.min(Math.floor(Dimensions.get('window').width - 40), 400),
            );
          }}
        >
          <Image
            src={playlistData.artwork_url
              ?.replace('{w}', String(imageSize))
              .replace('{h}', String(imageSize))}
            style={{ width: imageSize, height: imageSize, marginRight: '1em' }}
          />
        </View>

        <Link href={playlistData.url} target="_blank">
          <Box width={400}>
            <Text style={{ fontSize: '1.1rem', marginRight: '0.5rem' }}>
              Listen on
            </Text>
            {mapServiceTypesToIcons(playlistData.service, 32)}
          </Box>
        </Link>

        {playlistData &&
          playlistData.songs.map(
            ({
              id,
              song_name,
              artist_name,
              artwork_url,
              spotify_url,
              apple_url,
              youtube_id,
            }) => (
              <View style={{ marginBottom: '1rem' }} key={id}>
                <CompactSongLink
                  id={id}
                  key={id}
                  artist_name={artist_name}
                  song_name={song_name}
                  artwork_url={artwork_url}
                  songUrls={collectMediaLinks({
                    spotifyUrl: spotify_url,
                    appleUrl: apple_url,
                    youtubeId: youtube_id,
                  })}
                />
              </View>
            ),
          )}
      </View>
    </>
  );
}
