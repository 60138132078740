const getLogFn =
  (logFn: (message: string, data: unknown, ...args: any[]) => void) =>
  (message: string, data?: unknown, ...args: any[]) => {
    logFn(message, data, ...args);
  };

/* eslint-disable no-console */
export default {
  debug: getLogFn(console.debug),
  info: getLogFn(console.info),
  notice: getLogFn(console.warn),
  warn: getLogFn(console.warn),
  warning: getLogFn(console.warn),
  error: getLogFn(console.error),
};
