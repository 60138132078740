/*This file was generated by NativeWind. Do not edit!*/
const {
  NativeWindStyleSheet: _NativeWindStyleSheet
} = require("nativewind");
_NativeWindStyleSheet.create({
  styles: {
    "*": {
      "borderTopWidth": 0,
      "borderRightWidth": 0,
      "borderBottomWidth": 0,
      "borderLeftWidth": 0,
      "borderStyle": "solid",
      "borderTopColor": "#e5e7eb",
      "borderRightColor": "#e5e7eb",
      "borderBottomColor": "#e5e7eb",
      "borderLeftColor": "#e5e7eb"
    },
    "": {
      "borderTopWidth": 0,
      "borderRightWidth": 0,
      "borderBottomWidth": 0,
      "borderLeftWidth": 0,
      "borderStyle": "solid",
      "borderTopColor": "#e5e7eb",
      "borderRightColor": "#e5e7eb",
      "borderBottomColor": "#e5e7eb",
      "borderLeftColor": "#e5e7eb"
    },
    "html": {
      "lineHeight": 1.5,
      "fontFamily": "ui-sans-serif"
    },
    "body": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "hr": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "abbr:where([title])": {
      "textDecorationLine": "underline",
      "textDecorationColor": "black",
      "textDecorationStyle": "dotted"
    },
    "code": {
      "fontFamily": "ui-monospace",
      "fontSize": "1em"
    },
    "kbd": {
      "fontFamily": "ui-monospace",
      "fontSize": "1em"
    },
    "samp": {
      "fontFamily": "ui-monospace",
      "fontSize": "1em"
    },
    "pre": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "small": {
      "fontSize": "80%"
    },
    "sub": {
      "fontSize": "75%",
      "lineHeight": 0,
      "position": "relative"
    },
    "sup": {
      "fontSize": "75%",
      "lineHeight": 0,
      "position": "relative"
    },
    "button": {
      "backgroundColor": "transparent"
    },
    "input": {
      "opacity": 1,
      "color": "#9ca3af"
    },
    "optgroup": {
      "fontFamily": "inherit",
      "fontSize": "100%",
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0,
      "paddingTop": 0,
      "paddingRight": 0,
      "paddingBottom": 0,
      "paddingLeft": 0
    },
    "select": {
      "textTransform": "none"
    },
    "textarea": {
      "opacity": 1,
      "color": "#9ca3af"
    },
    "[type='button']": {
      "backgroundColor": "transparent"
    },
    "[type='reset']": {
      "backgroundColor": "transparent"
    },
    "[type='submit']": {
      "backgroundColor": "transparent"
    },
    ":-moz-ui-invalid": {
      "shadowOffset": {
        "width": 0,
        "height": 0
      },
      "shadowRadius": 0,
      "shadowColor": "black",
      "shadowOpacity": 1
    },
    "blockquote": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "dl": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "dd": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "h1": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "h2": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "h3": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "h4": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "h5": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "h6": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "figure": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "p": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "fieldset": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0,
      "paddingTop": 0,
      "paddingRight": 0,
      "paddingBottom": 0,
      "paddingLeft": 0
    },
    "legend": {
      "paddingTop": 0,
      "paddingRight": 0,
      "paddingBottom": 0,
      "paddingLeft": 0
    },
    "ol": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0,
      "paddingTop": 0,
      "paddingRight": 0,
      "paddingBottom": 0,
      "paddingLeft": 0
    },
    "ul": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0,
      "paddingTop": 0,
      "paddingRight": 0,
      "paddingBottom": 0,
      "paddingLeft": 0
    },
    "menu": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0,
      "paddingTop": 0,
      "paddingRight": 0,
      "paddingBottom": 0,
      "paddingLeft": 0
    },
    "img": {
      "maxWidth": "100%"
    },
    "video": {
      "maxWidth": "100%"
    },
    "[hidden]": {
      "display": "none"
    },
    "absolute": {
      "position": "absolute"
    },
    "relative": {
      "position": "relative"
    },
    "top-0": {
      "top": 0
    },
    "right-0": {
      "right": 0
    },
    "right-2": {
      "right": 8
    },
    "z-10": {
      "zIndex": 10
    },
    "m-auto": {
      "marginTop": "auto",
      "marginRight": "auto",
      "marginBottom": "auto",
      "marginLeft": "auto"
    },
    "mx-auto": {
      "marginLeft": "auto",
      "marginRight": "auto"
    },
    "my-1": {
      "marginTop": 4,
      "marginBottom": 4
    },
    "my-3": {
      "marginTop": 12,
      "marginBottom": 12
    },
    "my-4": {
      "marginTop": 16,
      "marginBottom": 16
    },
    "mt-16": {
      "marginTop": 64
    },
    "mb-4": {
      "marginBottom": 16
    },
    "mb-8": {
      "marginBottom": 32
    },
    "mt-10": {
      "marginTop": 40
    },
    "mb-36": {
      "marginBottom": 144
    },
    "mb-2": {
      "marginBottom": 8
    },
    "mr-2": {
      "marginRight": 8
    },
    "mr-4": {
      "marginRight": 16
    },
    "mt-4": {
      "marginTop": 16
    },
    "ml-4": {
      "marginLeft": 16
    },
    "flex": {
      "display": "flex"
    },
    "hidden": {
      "display": "none"
    },
    "h-[60%]": {
      "height": "60%"
    },
    "h-full": {
      "height": "100%"
    },
    "h-screen": {
      "height": 100
    },
    "h-14": {
      "height": 56
    },
    "h-80": {
      "height": 320
    },
    "min-h-screen": {
      "minHeight": 100
    },
    "w-[95%]": {
      "width": "95%"
    },
    "w-[93%]": {
      "width": "93%"
    },
    "w-[90%]": {
      "width": "90%"
    },
    "w-screen": {
      "width": 100
    },
    "w-full": {
      "width": "100%"
    },
    "w-3/4": {
      "width": "75%"
    },
    "max-w-2xl": {
      "maxWidth": 672
    },
    "flex-row": {
      "flexDirection": "row"
    },
    "flex-col": {
      "flexDirection": "column"
    },
    "items-end": {
      "alignItems": "flex-end"
    },
    "items-center": {
      "alignItems": "center"
    },
    "justify-center": {
      "justifyContent": "center"
    },
    "justify-between": {
      "justifyContent": "space-between"
    },
    "self-start": {
      "alignSelf": "flex-start"
    },
    "self-center": {
      "alignSelf": "center"
    },
    "rounded": {
      "borderTopLeftRadius": 4,
      "borderTopRightRadius": 4,
      "borderBottomRightRadius": 4,
      "borderBottomLeftRadius": 4
    },
    "rounded-sm": {
      "borderTopLeftRadius": 2,
      "borderTopRightRadius": 2,
      "borderBottomRightRadius": 2,
      "borderBottomLeftRadius": 2
    },
    "border": {
      "borderTopWidth": 1,
      "borderRightWidth": 1,
      "borderBottomWidth": 1,
      "borderLeftWidth": 1
    },
    "border-b": {
      "borderBottomWidth": 1
    },
    "border-zinc-100": {
      "borderTopColor": "#f4f4f5",
      "borderRightColor": "#f4f4f5",
      "borderBottomColor": "#f4f4f5",
      "borderLeftColor": "#f4f4f5"
    },
    "border-zinc-500": {
      "borderTopColor": "#71717a",
      "borderRightColor": "#71717a",
      "borderBottomColor": "#71717a",
      "borderLeftColor": "#71717a"
    },
    "border-b-neutral-200": {
      "borderBottomColor": "#e5e5e5"
    },
    "border-b-gray-900": {
      "borderBottomColor": "#111827"
    },
    "bg-slate-50": {
      "backgroundColor": "#f8fafc"
    },
    "bg-slate-200": {
      "backgroundColor": "#e2e8f0"
    },
    "bg-white": {
      "backgroundColor": "#fff"
    },
    "bg-gray-900": {
      "backgroundColor": "#111827"
    },
    "bg-gray-800": {
      "backgroundColor": "#1f2937"
    },
    "bg-neutral-700": {
      "backgroundColor": "#404040"
    },
    "bg-slate-700": {
      "backgroundColor": "#334155"
    },
    "bg-slate-800": {
      "backgroundColor": "#1e293b"
    },
    "fill-slate-800": {
      "fill": "#1e293b"
    },
    "fill-white": {
      "fill": "#fff"
    },
    "p-9": {
      "paddingTop": 36,
      "paddingRight": 36,
      "paddingBottom": 36,
      "paddingLeft": 36
    },
    "p-4": {
      "paddingTop": 16,
      "paddingRight": 16,
      "paddingBottom": 16,
      "paddingLeft": 16
    },
    "p-2": {
      "paddingTop": 8,
      "paddingRight": 8,
      "paddingBottom": 8,
      "paddingLeft": 8
    },
    "p-8": {
      "paddingTop": 32,
      "paddingRight": 32,
      "paddingBottom": 32,
      "paddingLeft": 32
    },
    "px-4": {
      "paddingLeft": 16,
      "paddingRight": 16
    },
    "py-2": {
      "paddingTop": 8,
      "paddingBottom": 8
    },
    "pt-2": {
      "paddingTop": 8
    },
    "pb-6": {
      "paddingBottom": 24
    },
    "font-bold": {
      "fontWeight": "700"
    },
    "text-red-700": {
      "color": "#b91c1c"
    },
    "text-slate-700": {
      "color": "#334155"
    },
    "text-gray-800": {
      "color": "#1f2937"
    },
    "text-slate-800": {
      "color": "#1e293b"
    },
    "text-fuchsia-300": {
      "color": "#f0abfc"
    },
    "text-purple-300": {
      "color": "#d8b4fe"
    },
    "text-orange-300": {
      "color": "#fdba74"
    },
    "text-white": {
      "color": "#fff"
    },
    "underline": {
      "textDecorationLine": "underline"
    },
    "opacity-50": {
      "opacity": 0.5
    },
    "text-sm": {
      "fontSize": 14,
      "lineHeight": 20
    },
    "text-xl": {
      "fontSize": 20,
      "lineHeight": 28
    },
    ".dark:border-zinc-500": {
      "borderTopColor": "#71717a",
      "borderRightColor": "#71717a",
      "borderBottomColor": "#71717a",
      "borderLeftColor": "#71717a"
    },
    ".dark:border-b-gray-900": {
      "borderBottomColor": "#111827"
    },
    ".dark:bg-gray-900": {
      "backgroundColor": "#111827"
    },
    ".dark:bg-gray-800": {
      "backgroundColor": "#1f2937"
    },
    ".dark:bg-neutral-700": {
      "backgroundColor": "#404040"
    },
    ".dark:bg-slate-700": {
      "backgroundColor": "#334155"
    },
    ".dark:bg-slate-800": {
      "backgroundColor": "#1e293b"
    },
    ".dark:fill-white": {
      "fill": "#fff"
    },
    ".dark:text-white": {
      "color": "#fff"
    },
    "md:h-80@0": {
      "height": 320
    },
    "md:w-full@0": {
      "width": "100%"
    },
    "md:max-w-2xl@0": {
      "maxWidth": 672
    },
    "md:self-start@0": {
      "alignSelf": "flex-start"
    },
    "lg:w-3/4@0": {
      "width": "75%"
    }
  },
  atRules: {
    "md:h-80": [[["media", "(min-width: 768px)"]]],
    "md:w-full": [[["media", "(min-width: 768px)"]]],
    "md:max-w-2xl": [[["media", "(min-width: 768px)"]]],
    "md:self-start": [[["media", "(min-width: 768px)"]]],
    "lg:w-3/4": [[["media", "(min-width: 1024px)"]]]
  },
  topics: {
    "h-screen": ["height"],
    "min-h-screen": ["height"],
    "w-screen": ["width"],
    "md:h-80": ["width"],
    "md:w-full": ["width"],
    "md:max-w-2xl": ["width"],
    "md:self-start": ["width"],
    "lg:w-3/4": ["width"]
  },
  units: {
    "h-screen": {
      "height": "vh"
    },
    "min-h-screen": {
      "minHeight": "vh"
    },
    "w-screen": {
      "width": "vw"
    }
  }
});