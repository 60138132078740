import React from 'react';
import {
  SafeAreaView,
  StatusBar,
  View,
  ScrollView,
  useColorScheme,
} from 'react-native';
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { LoggedInProvider } from '~/client/utils/LoggedInContext';
import Home from '~/client/Home';
import Song from '~/client/Song';
import Embed from '~/client/Embed';
import Playlist from '~/client/Playlist';
import Album from '~/client/Album';
import Terms from '~/client/Terms';
import FinishSignup from '~/client/Signup';
import ForgotPassword from '~/client/ForgotPassword';
import UpdatePassword from '~/client/UpdatePassword';
import WaitingQueue from '~/client/WaitingQueue';
import Nav from '~/client/components/Nav/Nav';
import StartSignup from '~/client/components/Nav/Signup';
import Tagged from '~/client/Tagged';
import isWeb from '~/client/utils/isWeb';
import { RootStackParamList, LinkingConfig } from '~/client/NavigationConfig';

const Stack = createNativeStackNavigator<RootStackParamList>();

export default function App() {
  const scheme = useColorScheme();

  const webstyle = 'bg-slate-50 dark:bg-gray-900 min-h-screen';

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <StatusBar />
      <LoggedInProvider>
        <NavigationContainer
          linking={LinkingConfig}
          theme={scheme === 'dark' ? DarkTheme : DefaultTheme}
        >
          <ScrollView
            contentContainerStyle={{ flex: 1 }}
            className={isWeb() ? webstyle : ''}
            stickyHeaderIndices={[0]}
          >
            <Nav />
            <Wrapper>
              <Stack.Navigator
                initialRouteName="Home"
                screenOptions={{ headerShown: false }}
              >
                <Stack.Screen name="Home" component={Home} />
                <Stack.Screen name="Tagged" component={Tagged} />
                <Stack.Screen name="Waiting" component={WaitingQueue} />
                <Stack.Screen name="Song" component={Song} />
                <Stack.Screen name="Embed" component={Embed} />
                <Stack.Screen name="Playlist" component={Playlist} />
                <Stack.Screen name="Album" component={Album} />
                {/* @ts-expect-error */}
                <Stack.Screen name="StartSignup" component={StartSignup} />
                <Stack.Screen name="FinishSignup" component={FinishSignup} />
                <Stack.Screen
                  name="ForgotPassword"
                  component={ForgotPassword}
                />
                <Stack.Screen
                  name="UpdatePassword"
                  component={UpdatePassword}
                />
                <Stack.Screen name="Terms" component={Terms} />
              </Stack.Navigator>
            </Wrapper>
          </ScrollView>
        </NavigationContainer>
      </LoggedInProvider>
    </SafeAreaView>
  );
}

function Wrapper({ children }: { children: React.ReactNode }) {
  if (isWeb()) {
    return (
      <View className="w-[95%] lg:w-3/4 mx-auto bg-slate-200">{children}</View>
    );
  }

  return <>{children}</>;
}
