import React from 'react';
import { ScrollView } from 'react-native';

import ArtworkResourceLink from '~/client/components/ArtworkResourceLink';
import TitleBox from '~/client/components/TitleBox';
import { ResourceTypes } from '~/client/NavigationConfig';

export function ArtworkResourceList({
  title,
  resourceList,
  type,
}: {
  title: string;
  resourceList: { id: number | string; artwork_url?: string; href: string }[];
  type: ResourceTypes;
}) {
  return (
    <TitleBox title={title}>
      <ScrollView horizontal style={{}}>
        {resourceList.map((resource) => {
          return (
            <ArtworkResourceLink
              type={type}
              href={resource.href}
              resourceId={resource.id}
              key={resource.id}
              artwork_url={resource.artwork_url || ''}
            />
          );
        })}
      </ScrollView>
    </TitleBox>
  );
}
