import React from 'react';
import { View } from 'react-native';
import Text from '~/client/components/Text';

export default function Caption(props: any) {
  return (
    <View className="my-1">
      <Text>{props.children}</Text>
    </View>
  );
}
