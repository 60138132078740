import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';

import { useLoggedIn } from '~/client/utils/LoggedInContext';
import CompactSongLink from '~/client/components/CompactSongLink';
import Text from '~/client/components/Text';

import collectMediaLinks from '~/client/utils/collectMediaLinks';
import type { RootStackParamList } from '~/client/NavigationConfig';
import fetchJSONFromServer from '~/client/utils/fetchJSONFromServer';

export default function Tagged(
  props: NativeStackScreenProps<RootStackParamList, 'Tagged'>,
) {
  const tag = props?.route?.params?.tag ?? 'favorites';

  const loggedInUser = useLoggedIn();
  const [songs, setSongs] = useState<
    | {
        name: string;
        artist: string;
        id: string;
        song_name: string;
        artist_name: string;
        artwork_url: string;
        spotify_url: string;
        apple_url: string;
        youtube_id: string;
      }[]
    | null
  >();

  useEffect(() => {
    const fetchData = async () => {
      if (loggedInUser?.email) {
        const responseBody = await fetchJSONFromServer(`/tag/${tag}?${Date.now()}`);

        setSongs(responseBody);
      }
    };

    fetchData();
  }, [loggedInUser]);

  return (
    <>
      <View className="p-4 mt-16 mb-4 bg-white drop-shadow rounded-sm dark:bg-gray-800">
        {songs?.length ? (
          songs?.map(
            ({
              id,
              song_name,
              artist_name,
              artwork_url,
              spotify_url,
              apple_url,
              youtube_id,
            }) => (
              <View style={{ marginBottom: '1rem' }} key={id}>
                <CompactSongLink
                  id={id}
                  key={id}
                  artist_name={artist_name}
                  song_name={song_name}
                  artwork_url={artwork_url}
                  songUrls={collectMediaLinks({
                    spotifyUrl: spotify_url,
                    appleUrl: apple_url,
                    youtubeId: youtube_id,
                  })}
                />
              </View>
            ),
          )
        ) : (
          <Text>Tap on a song to add it to your Favorites.</Text>
        )}
      </View>
    </>
  );
}
