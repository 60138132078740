import React from 'react';
import {
  ActivityIndicator as RNActivityIndicator,
  ActivityIndicatorProps,
  Platform,
} from 'react-native';
import { ProgressBar } from 'react-native-web';

export default function ActivityIndicator({
  size = 'large',
  color = '#999999',
  ...props
}: ActivityIndicatorProps) {
  if (Platform.OS === 'web') {
    return <ProgressBar indeterminate color="white" trackColor="gray" />;
  }

  return <RNActivityIndicator size={size} color={color} {...props} />;
}
