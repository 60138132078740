import React, { HTMLInputTypeAttribute } from 'react';
import {
  View,
  TextInput as RNTextInput,
  NativeSyntheticEvent,
  TextInputChangeEventData,
  TextInputIOSProps,
  TextInputAndroidProps,
} from 'react-native';

export interface TextInputCommon {
  id: string;
  type: 'text' | 'url' | 'email' | 'password';
  name: string;
  placeholder: string;
  onChange?: (event: NativeSyntheticEvent<TextInputChangeEventData>) => void;
  // TODO: remove?
  onSubmitEditing?: () => void;
}

const INPUT_TYPE_MAPPINGS = new Map<
  HTMLInputTypeAttribute,
  {
    // https://reactnative.dev/docs/textinput#textcontenttype-ios
    iOS?: TextInputIOSProps['textContentType'];
    // https://reactnative.dev/docs/textinput#autocomplete-android
    Android?: TextInputAndroidProps['autoComplete'];
  }
>([
  ['password', { iOS: 'password', Android: 'password' }],
  ['url', { iOS: 'URL' }],
  ['email', { iOS: 'emailAddress', Android: 'email' }],
]);

export default function TextInput(
  props: TextInputCommon & {
    ref?: React.Ref<RNTextInput>;
  },
) {
  const nativeInputType = INPUT_TYPE_MAPPINGS.get(props.type);

  return (
    <View>
      <RNTextInput
        className="p-2 text-xl mb-4 bg-white dark:bg-neutral-700 border rounded-sm border-zinc-100 dark:border-zinc-500 text-gray-800 dark:text-white "
        secureTextEntry={props.type === 'password' ? true : false}
        textContentType={nativeInputType?.iOS}
        autoComplete={nativeInputType?.Android}
        placeholderTextColor={'#757575'}
        {...props}
      />
    </View>
  );
}
