import React from 'react';

import Text from '~/client/components/Text';
import { TITLE_TEXT_SIZE } from '~/client/components/constants';

export default function TitleText({ children }: { children: React.ReactNode }) {
  return (
    <Text style={{ fontSize: TITLE_TEXT_SIZE, fontWeight: 'bold' }}>
      {children}
    </Text>
  );
}
