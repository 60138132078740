import { Services } from '~/client/utils/constants';

export default function ({
  spotifyUrl,
  appleUrl,
  youtubeId,
}: {
  [key: string]: string;
}): { service: Services; url: string }[] {
  const mediaUrls = [];

  spotifyUrl && mediaUrls.push({ service: Services.spotify, url: spotifyUrl });

  appleUrl && mediaUrls.push({ service: Services.apple, url: appleUrl });

  youtubeId &&
    mediaUrls.push({
      service: Services.youtube,
      url: `https://youtu.be/${youtubeId}`,
    });

  return mediaUrls;
}
