import React, { useState } from 'react';
import { View, Pressable } from 'react-native';

import SubmitUrl from '~/client/components/SubmitUrl';
import Text from '~/client/components/Text';

import { RecentSubmission } from '~/client/types';

export type TRecentAlbums = RecentSubmission[];

export enum TAB_NAMES {
  SUBMIT_URL = 'submit_url',
}

function Tab({
  active,
  onPress,
  children,
  tabName,
}: {
  active: boolean;
  onPress: () => void;
  children: React.ReactNode;
  tabName: TAB_NAMES;
}) {
  return (
    <Pressable onPress={onPress}>
      <Text
        style={{
          textDecoration: active ? 'underline' : '',
        }}
      >
        {children}
      </Text>
    </Pressable>
  );
}

export default function ActionBar({
  recentAlbums,
}: {
  recentAlbums: TRecentAlbums;
}) {
  const [activeTab, setActiveTab] = useState<TAB_NAMES>(TAB_NAMES.SUBMIT_URL);
  return (
    <View className="p-4 bg-white drop-shadow rounded-sm dark:bg-gray-800">
      <View className="flex-row">
        <Tab
          tabName={TAB_NAMES.SUBMIT_URL}
          onPress={() => {
            setActiveTab(TAB_NAMES.SUBMIT_URL);
          }}
          active={activeTab === TAB_NAMES.SUBMIT_URL}
        >
          Add Links
        </Tab>
      </View>

      <SubmitUrl
        active={activeTab === TAB_NAMES.SUBMIT_URL}
        mostRecentSubmission={recentAlbums[0]}
      />
    </View>
  );
}
