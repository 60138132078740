import React from 'react';

import AppleMusicIcon from '~/client/images/AppleMusicIcon';
import YouTubeIcon from '~/client/images/YouTubeIcon';
import SpotifyIcon from '~/client/images/SpotifyIcon';
import { Services } from '~/client/utils/constants';

export default function mapServiceTypesToIcons(
  service: Services,
  size: number,
) {
  if (service === Services.apple) {
    return <AppleMusicIcon width={size} />;
  }

  if (service === Services.spotify) {
    return <SpotifyIcon width={size} />;
  }

  if (service === Services.youtube) {
    return <YouTubeIcon width={size} />;
  }
}
