import React from 'react';
import { View, Text } from 'react-native';
import { SongLink } from '~/client/components/SongLink';
import Link from '~/client/components/Link';
import Image from '~/client/components/Image';
import { Services } from '~/client/utils/constants';
import ServiceIcon from '~/client/components/ServiceIcon';
import useTextColor from '~/client/hooks/useTextColor';

interface CompactSongLink
  extends Omit<SongLink, 'artist_name' | 'artwork_url'> {
  artist_name?: string;
  spotify_url?: string;
  apple_url?: string;
  youtube_id?: string;
  track_number?: number;
  artwork_url?: string;
  songUrls: { service: Services; url: string }[];
  options?: options;
}

interface options {
  textColor: string;
}

function renderSongText({
  artistName,
  songName,
}: {
  artistName?: string;
  songName: string;
}) {
  if (artistName == null) {
    return songName;
  }

  return `${artistName} - ${songName}`;
}

export default function ({
  artist_name,
  song_name,
  id,
  artwork_url,
  href,
  songUrls,
  track_number,
  ...extraProps
}: CompactSongLink) {
  const { options } = extraProps;
  const songColor = options?.textColor
    ? `#${options.textColor}`
    : useTextColor();
  return (
    <View
      key={id}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Link
        href={href || `/l/${id}`}
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          maxWidth: '50%',
        }}
      >
        {artwork_url && (
          <Image
            src={artwork_url.replace('{w}', '40').replace('{h}', '40')}
            style={{
              width: 40,
              height: 40,
              marginRight: '1em',
            }}
          />
        )}
        {track_number && (
          <Text
            style={{
              fontWeight: 'bold',
              marginRight: '0.5rem',
              color: songColor,
            }}
          >
            {track_number}
          </Text>
        )}

        <Text
          style={{ marginRight: '0.5rem', color: songColor }}
          numberOfLines={1}
        >
          {renderSongText({ artistName: artist_name, songName: song_name })}
        </Text>
      </Link>
      {songUrls.map(({ service, url }) => (
        <ServiceIcon
          key={service}
          songUrl={url}
          service={service}
          style={{ marginRight: 8 }}
        />
      ))}
    </View>
  );
}
