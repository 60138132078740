import React, { forwardRef, ForwardedRef, useState, useEffect } from 'react';
import { View, Pressable, Modal, Button } from 'react-native';
import isEmail from 'validator/lib/isEmail';

import TextInput from '~/client/components/TextInput';
import XIcon from '~/client/images/XIcon';
import Error from '~/client/components/Error';
import { fetchFromServer } from '~/client/utils/fetchJSONFromServer';

interface SignupProps {
  handleClickOutside: (e: Event) => void;
  handleClickedSignup: () => void;
  handleDismiss: () => void;
  visible: boolean;
}

const Signup = forwardRef<View, SignupProps>(
  (props: SignupProps, ref: ForwardedRef<View>) => {
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [requestingConfirmation, setRequestingConfirmation] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async () => {
      setError(null);

      if (!isEmail(email)) {
        setError('Please enter a valid email address.');
        return;
      }

      setError(null);

      const res = await fetchFromServer('/auth/signup', {
        method: 'POST',
        body: JSON.stringify({ email }),
      });
      if (res.status === 200) {
        setEmailSent(true);
      }
    };

    useEffect(() => {
      // TODO: cooler transition than this
      if (emailSent) {
        setRequestingConfirmation(true);
      }
    }, [emailSent]);

    return (
      <Modal animationType="none" transparent={true} visible={props.visible}>
        <View
          className="w-[90%] h-[60%] md:max-w-2xl md:h-80 m-auto p-4 bg-white drop-shadow rounded-sm dark:bg-slate-700 flex justify-between z-10"
          ref={ref}
        >
          <Pressable className="flex items-end" onPress={props.handleDismiss}>
            <XIcon
              width={10}
              height={10}
              className="fill-slate-800 dark:fill-white"
            />
          </Pressable>

          <View className="h-full flex flex-col justify-center items-center">
            <Error errorText={error} />
            {!requestingConfirmation ? (
              <>
                <TextInput
                  placeholder="email"
                  type="email"
                  id="email"
                  name="email"
                  onChange={({ nativeEvent: { text } }) => setEmail(text)}
                  onSubmitEditing={handleSubmit}
                />

                <Button title="Email Me a Signup Link" onPress={handleSubmit} />
              </>
            ) : (
              <View>
                Signup link sent. Check your email.
                <Button onPress={props.handleDismiss} title="ok" />
              </View>
            )}
          </View>
        </View>
        <View className="h-screen w-screen opacity-50 absolute bg-slate-200 dark:bg-slate-800" />
      </Modal>
    );
  },
);

export default Signup;
