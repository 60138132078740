import * as React from 'react';
import Svg, {
  SvgProps,
  Defs,
  LinearGradient,
  Stop,
  G,
  Circle,
  Path,
} from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      viewBox="0 0 48 48"
      width={props.width}
      height={props.width}
      {...props}
    >
      <Defs>
        <LinearGradient
          id="prefix__b"
          x1={51.56}
          y1={61.46}
          x2={51.988}
          y2={14.3}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#23cf5f" />
          <Stop offset={1} stopColor="#60ff98" />
        </LinearGradient>
        <LinearGradient
          id="prefix__a"
          gradientUnits="userSpaceOnUse"
          x1={53.57}
          y1={62.47}
          x2={53.798}
          y2={13.896}
        >
          <Stop stopColor="#2e3436" />
          <Stop offset={1} stopColor="#555753" />
        </LinearGradient>
      </Defs>
      <G transform="translate(-28.371 -14.278) scale(.99999)">
        <Circle r={24} cy={38.28} cx={52.37} fill="url(#prefix__a)" />
        <Path
          d="M52.38 14.285c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.746 24-24c0-13.255-10.745-24-24-24m-3.522 12.526c7.05 0 14.462 1.45 19.878 4.631.73.413 1.237 1.037 1.237 2.18 0 1.308-1.052 2.249-2.264 2.249-.489 0-.779-.119-1.237-.37-4.344-2.605-11.09-4.04-17.605-4.04-3.254 0-6.56.331-9.587 1.153-.349.088-.789.262-1.231.262-1.28 0-2.263-1.013-2.263-2.293 0-1.304.808-2.036 1.68-2.294 3.421-1.01 7.24-1.478 11.391-1.478m-.489 8.247c6.282 0 12.359 1.565 17.14 4.424.801.459 1.096 1.043 1.096 1.897a1.867 1.867 0 01-1.871 1.879c-.521 0-.848-.21-1.202-.416-3.904-2.317-9.315-3.856-15.24-3.856-3.039 0-5.662.426-7.833 1-.468.129-.73.267-1.168.267a1.88 1.88 0 01-1.875-1.882c0-1.021.495-1.723 1.493-2 2.698-.741 5.453-1.313 9.463-1.313m.329 7.837c5.252 0 9.932 1.205 13.96 3.619.599.35.951.708.951 1.595 0 .865-.704 1.501-1.493 1.501-.388 0-.653-.135-1.016-.356-3.478-2.104-7.815-3.211-12.418-3.211-2.568 0-5.153.329-7.57.833-.393.085-.888.237-1.183.237a1.498 1.498 0 01-1.519-1.508c0-1.01.582-1.511 1.306-1.649 2.964-.676 5.917-1.061 8.983-1.061"
          fill="url(#prefix__b)"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
