import React from 'react';
import { View } from 'react-native';

import { Link } from '@react-navigation/native';
import Image from '~/client/components/Image';
import { ResourceTypes } from '~/client/NavigationConfig';
import { getNavigationParams } from '~/client/utils/navigateToResource';

const size = 200;

export default function ({
  artwork_url,
  href,
  type,
  resourceId,
}: {
  artwork_url: string;
  href: string;
  type: ResourceTypes;
  resourceId: number | string;
}) {
  return (
    <View className="mb-2" key={artwork_url + href}>
      <Link
        to={getNavigationParams({
          resourceType: type,
          resourceId: resourceId.toString(),
        })}
      >
        <Image
          className="mr-2"
          src={artwork_url
            .replace('{w}', size.toString())
            .replace('{h}', size.toString())}
          style={{ width: size, height: size }}
        />
      </Link>
    </View>
  );
}
