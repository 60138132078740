import { getStateFromPath, LinkingOptions } from '@react-navigation/native';

import { BASE_URL, CUSTOM_URL_SCHEME } from '~/client/utils/constants';

type ResourceScreenParams = { resourceId: string };

export type RootStackParamList = {
  Home: undefined;
  Tagged: { tag: string };
  Waiting: { resourceSourceUrl: string };
  Song: ResourceScreenParams;
  Embed: { resourceSourceUrl: string };
  Playlist: ResourceScreenParams;
  Album: ResourceScreenParams;
  StartSignup: undefined;
  FinishSignup: undefined;
  ForgotPassword: undefined;
  UpdatePassword: undefined;
  Terms: undefined;
};
export type ResourceTypes = keyof Pick<
  RootStackParamList,
  'Song' | 'Album' | 'Playlist'
>;

export const LinkingConfig: LinkingOptions<Record<string, unknown>> = {
  prefixes: [BASE_URL, CUSTOM_URL_SCHEME],
  config: {
    screens: {
      Home: '/',
      Tagged: '/tag/:tagName',
      Waiting: '/waiting/:resourceSourceUrl',
      Song: '/l/:resourceId',
      Embed: '/e/:resourceSourceUrl',
      Playlist: '/pl/:resourceId',
      Album: '/al/:resourceId',
      StartSignup: '/start-signup',
      FinishSignup: '/finish-signup',
      ForgotPassword: '/forgot-password',
      UpdatePassword: '/update-password',
      Terms: '/terms',
    },
  },
  getStateFromPath: (path, options) => {
    const SPECIAL_PATHS = [
      { pathPart: '/e/', routeName: 'Embed' },
      { pathPart: '/waiting/', routeName: 'Waiting' },
    ];

    const matchingPath = SPECIAL_PATHS.find((specialPath) =>
      path.startsWith(specialPath.pathPart),
    );

    if (!matchingPath) {
      return getStateFromPath(path, options);
    }

    const [, resourceSourceUrl] = path.split(matchingPath.pathPart);

    return {
      routes: [
        {
          name: matchingPath.routeName,
          params: {
            resourceSourceUrl,
          },
          path: `${matchingPath.pathPart}/${resourceSourceUrl}`,
        },
      ],
    };
  },
};
